import React from 'react'
import { Box, Stack, LightMode } from '@chakra-ui/react'
import BlogEntry from './BlogEntry'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'

const BlogBody = () => {
  return (
    <LightMode>
      <ContentMaxWidth maxW="1600px" h="100%" alignItems="start">
        <Box py="64px">
          <Stack
            gridTemplateColumns="repeat(auto-fit, minmax(320px, 1fr));"
            gap="16px"
          >
            <BlogEntry
              delay={0}
              bgSize="50%"
              title="Integration Architecture and Why Reach Took the Hard, But Best Route "
              description="In today’s fast-evolving cybersecurity landscape, integration architecture is not just a necessary feature, but the backbone of how security systems communicate and protect organizations. Reach has approached integrations from a unique perspective, opting to take the hard road of building a system that is not tied solely to best practices, but fundamentally designed to adapt, learn, and integrate with purpose-built AI. It’s a decision that reflects our commitment to innovation, a deep understanding of the cybersecurity market alongside the products within a customer’s ecosystem, and a belief that the best path is rarely the easiest one. "
              imageUrl={
                'https://reach-public-static-assets.s3.us-west-1.amazonaws.com/quest-images/OKTA_REDUCED_SESSION.png'
              }
              internal
              url="/resources/blog/integration_architecture_blog"
            />
            <BlogEntry
              delay={0}
              bgSize="50%"
              title="Going Above and Beyond Microsoft’s Impending MFA Requirement"
              description="Beginning in October, Microsoft will mandate multi-factor authentication (MFA) for multiple Azure components, including the Entra admin portal. Given Microsoft’s research indicates that MFA can prevent over 99.2% of account compromise attempts, this is great news! For those utilizing Entra Conditional Access to govern how users authenticate to mission-critical applications, there are additional measures you can take within Entra to further reduce the risk of credential compromise. Let’s explore some strategies to elevate your Conditional Access implementation beyond MFA using the features you’re licensed for."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/quest-images/CONDITIONAL_ACCESS_SIGN_ON_SHORT_SESSION_HIGH_RISK_MFA.png"
              internal
              url="/resources/blog/microsoft_entra_mfa"
            />
            <BlogEntry
              delay={0}
              bgSize="50%"
              title="Reach Security mentioned in the 2024 Gartner® Innovation Insight: Automated Security Control Assessment"
              description="While Gartner is helping to shape this new market category, companies like Autodesk and Nutanix are already realizing the benefits of this approach through Reach’s platform. By focusing on continuous security control assessments that leverage both business and adversarial threat context, Reach helps organizations like Autodesk and Nutanix understand the specific risks they face. Reach automates configuration changes to align with these risks, while also ensuring that organizations maximize the value of their existing security tools."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/Gartner_logo.svg"
              internal
              url="/resources/blog/gartner_blog"
            />
            <BlogEntry
              delay={0}
              bgSize="50%"
              title="Countering Phishing and Credential Theft with MITRE D3FEND: How Reach Keeps You Ahead of the Threats"
              description="Phishing and credential theft remain two of the top methods adversaries use to breach networks. To counter these effectively, it’s not enough to understand the attacks themselves—you also need a strong defensive strategy. That’s where a framework like MITRE D3FEND can make all the difference, providing a structured approach to securing your organization. "
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/d3fendBlog.png"
              internal
              url="/resources/blog/mitre_d3fend_blog"
            />
            <BlogEntry
              delay={0}
              bgSize="50%"
              title="The Fast and the Furious – Configuration Drift"
              description="As organizations expand and evolve, the relationship between IT and security becomes equally as important as it is complex. The rapid pace of technology changes to drive revenue and streamline operations, coupled with an increasing number of sophisticated cyber threats, forces both IT and security teams to constantly adapt. This dynamic environment makes it challenging to maintain the integrity of security controls while ensuring operational efficiency and availability. For security teams, this challenge is especially evident when it comes to monitoring and maintaining established security controls from a configuration lens."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/config_drift_cover.png"
              internal
              url="/resources/blog/configuration_drift"
            />
            <BlogEntry
              delay={0}
              bgSize="50%"
              title="Cloudy with a Chance of Account Takeover"
              description="Integrating Reach with Okta is a game-changer for boosting your security in the event of credential compromise. 
              With Reach’s advanced analytics and proactive recommendations, organizations can better defend against threats from attackers 
              looking to spoof their location and gain access to sensitive infrastructure. This combo not only maximizes the value of Okta’s Adaptive features,
              but also adds an extra layer of security for a more comprehensive approach to your IAM deployment."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/Okta_Wordmark_CMYK_Black.png"
              internal
              url="/resources/blog/okta_integration"
            />
            <BlogEntry
              delay={0}
              bgSize="50%"
              title="The Power of Automation with Reach"
              description="At Reach, we prioritize the safety and control of our users by
                leveraging a staged environment for automation. This approach
                not only safeguards the production environment but also ensures
                that changes are meticulously tested and approved before going
                live. Whether it’s implementing Conditional Access policies or
                deploying other critical updates, Reach’s staged automation
                empowers organizations to increase their cybersecurity posture
                with confidence, efficiency, and ease."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/automation_blog_cover.png"
              internal
              url="/resources/blog/automation"
            />
            <BlogEntry
              delay={0}
              bgSize="80%"
              title="Bridging the Last Mile: Reach’s New ServiceNow Integration Enhances Cybersecurity Maturity  "
              description="As we continue to navigate the complexities of cybersecurity, our focus remains on providing solutions that not only secure but also streamline operational processes. The new ServiceNow integration is a testament to our dedication to mastering the last mile, ensuring that our clients can achieve and maintain the highest levels of security maturity."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/servicenow-footer-logo.svg"
              internal
              url="/resources/blog/servicenow_integration"
            />
            <BlogEntry
              delay={0}
              bgSize="80%"
              title="The Last Mile in Cybersecurity: Turning Assessments into Action"
              description="The last mile is a critical component of cybersecurity operations. By embracing a comprehensive approach that goes beyond mere reports to actionable and automated implementation, Reach ensures that your organization is not only secure but also agile and efficient. Stay tuned for our upcoming posts where we will delve deeper into specific use cases and solutions that exemplify our commitment to mastering the last mile in cybersecurity."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/ASCA_maturity.png"
              internal
              url="/resources/blog/last_mile"
            />
            <BlogEntry
              delay={0}
              bgSize="80%"
              title="Security Tools Optimization Spotlight: Microsoft Entra ID
              Conditional Access"
              description="Conditional Access contains simple yet powerful sign-on
              attributes that can be tailored to your business with a
              risk-based approach. Whether you're undergoing security tool
              consolidation efforts or embarking on an IAM hardening project,
              consider starting with a series of tailored sign-on policies
              that address risk where most breaches start."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/quest-images/thumbnails/CONDITIONAL_ACCESS_SIGN_ON_BLOCK_HIGH_RISK_EXCLUDE_MANAGED_OR_REGISTERED.png"
              internal
              url="/resources/blog/microsoft_entra_spotlight"
            />

            <BlogEntry
              delay={0}
              bgSize="80%"
              title="Why an identity-centric approach to Tools Rationalization is the place to start"
              description='You may hear terms like "tools rationalization" or "maximizing tool effectiveness," but their meanings can vary. Are you following a vendor best practice checklist, mapping CVEs to specific configurations, or tracking workforce risk and ensuring your licensed capabilities address this risk? Whichever path you choose, understanding the value of each approach is crucial.'
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/quest-images/PANOS_DNS_FILTERING.png"
              internal
              url="/resources/blog/identity_centric_tools"
            />
            <Box />
          </Stack>
        </Box>
      </ContentMaxWidth>
    </LightMode>
  )
}

export default BlogBody
