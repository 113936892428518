/* eslint-disable react/no-unescaped-entities */
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import PageHeader from 'components/Shared/PageHeader'
import { useApp } from 'context/App'
import React, { useEffect } from 'react'
// import BlogAuthor from '../../BlogAuthor'
import PageFooter from 'components/Shared/PageFooter'
// import nick from './nick3.png'
import programs from './programs.png'
import quest from './quest.png'
import colt_talk from './colt_talk.png'
import garrett_and_colt from './garrett_and_colt.png'
import B from 'components/Shared/B'
import { BlogImage } from '../TRABlog'
import gary from './gary.jpeg'
import BlogAuthor from '../../BlogAuthor'

const IntegrationArchitectureBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])
  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Integration Architecture and Why Reach Took the Hard, But Best
                Route
              </Heading>
              <Flex py="8px" alignItems="center" justifyContent="space-around">
                <Box fontSize="12px">October 10, 2024</Box>
                <BlogAuthor name="Gary Mello" img={gary} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box mb="16px">
                In today’s fast-evolving cybersecurity landscape, integration
                architecture is not just a necessary feature, but the backbone
                of how security systems communicate and protect organizations.
                Reach has approached integrations from a unique perspective,
                opting to take the hard road of building a system that is not
                tied solely to best practices, but fundamentally designed to
                adapt, learn, and integrate with purpose-built AI. It’s a
                decision that reflects our commitment to innovation, a deep
                understanding of the cybersecurity market alongside the products
                within a customer’s ecosystem, and a belief that the best path
                is rarely the easiest one.
              </Box>

              <Heading size="md" mb="16px">
                The three core pillars of Reach’s API integrations are:
              </Heading>

              <Box mb="16px">
                <B>Data Interrogation</B> – Our platform collects threat data
                from various security controls, providing comprehensive
                visibility into an organization’s exposure. This also enables
                identification of high-risk users who are being targeted.
              </Box>

              <Box mb="64px">
                <B>Modeling of Controls</B> – The integrations provide a deep
                understanding of what controls are available, what controls are
                deployed, and how the controls can be used. The depth of the
                modeling is what ultimately helps an organization prioritize
                their controls, mobilize change and continuously validate their
                risk posture.
              </Box>

              <BlogImage src={programs} mb="64px" />

              <Box mb="64px">
                <B>Mobilizing Change</B> – Beyond providing insights and
                recommendations, Reach also completes the ‘last mile’ of the
                process by pushing these configurations via Quests to both
                change management systems (e.g. Jira or ServiceNow) and directly
                to the security products in a staged setting.
              </Box>

              <BlogImage src={quest} mb="64px" />

              <Box mb="16px">
                What truly sets Reach apart is our deliberate choice to leverage
                purpose-built AI to power our integrations. While many vendors
                settle for best practices or off-the-shelf AI models, Reach’s
                architecture integrates AI specifically crafted for each
                customer’s unique environment and threat profile. For example,
                imagine an organization facing a surge in phishing attacks.
                Reach doesn’t just integrate with their email security tools to
                say "here are the features Proofpoint uses to stop phishing
                attempts”; it goes further. It analyzes patterns of phishing
                emails, cross-references them with endpoint intelligence and
                identity, and recommends the exact security configurations for
                the features you license that will reduce your specific risk
                debt. This level of adaptability and contextual awareness is
                what sets Reach apart in the market. In cybersecurity, context
                is everything, and GenAI models often miss the nuanced threat
                behaviors specific to each environment.
              </Box>

              <Box mb="64px">
                Many companies shy away from building AI models due to the
                upfront complexity, opting to use GenAI instead. Reach’s CTO,
                Colt Blackmore, highlighted in his{' '}
                <Link
                  href="https://www.youtube.com/watch?v=gIo3a488E0g"
                  fontWeight="bold"
                  target="_blank"
                >
                  BSidesLV talk
                </Link>{' '}
                that best practices can leave organizations vulnerable in ways
                they don’t initially foresee. The choice to build something from
                the ground up isn’t about reinventing the wheel; it’s about
                ensuring that the architecture can support the ever-changing
                nature of cybersecurity threats coincided with the security
                tooling available within the environment.
              </Box>

              <BlogImage src={colt_talk} mb="64px" />

              <Box mb="64px">
                Our integration architecture at Reach also emphasizes
                adaptability. As discussed in the{' '}
                <Link
                  href="https://www.youtube.com/watch?v=DOSL6ZjSUn0"
                  fontWeight="bold"
                  target="_blank"
                >
                  presentation at SAINTCON
                </Link>{' '}
                by our founders, the world of cybersecurity is not static, and
                neither should the technology designed to protect it be. Best
                practices are a great starting point, but they can quickly
                become outdated as threats evolve. A flexible, well-architected
                integration system supported by AI, offers superior protection
                compared to more rigid, one-size-fits-all solutions. In essence,
                Reach isn’t just offering basic integrations; we are offering an
                intelligent, forward-thinking way of integrating with a
                customer’s security ecosystem and securing their environment
                with a contextual approach based on their unique threat profile
                and the security tools within their environment.
              </Box>

              <BlogImage src={garrett_and_colt} mb="64px" />

              <Box mb="16px">
                In conclusion, Reach’s integration architecture may be the hard
                road, but it is undoubtedly the best one. Our decision to take
                this more complex route reflects a deep understanding of the
                security space, along with its customers’ unique threat profiles
                and security stacks, and the challenges they present. By
                prioritizing context, leveraging purpose-built AI, and
                understanding the nuances of cybersecurity tools, we have built
                a platform that can meet the needs of today’s threats while
                remaining adaptable for tomorrow’s challenges. This is a path
                that fewer companies are willing to take, but as the
                cybersecurity landscape continues to evolve, those that do will
                be the ones best equipped to handle what lies ahead.
              </Box>

              <Heading size="md" mb="16px">
                More About Reach Security
              </Heading>
              <Box mb="16px">
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default IntegrationArchitectureBlog
